import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppleCallbackComponent } from './auth/apple-callback/apple-callback.component';
import { LiveResolve } from './live/live.resolver';
import { AdsComponent } from './ads/ads.component';


const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('@app/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'live',
        loadChildren: () =>
            import('@app/live/live.module').then((m) => m.LiveModule),
    },
    {
        path: 'explore',
        loadChildren: () =>
            import('@app/explore/explore.module').then((m) => m.ExploreModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@app/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('@app/profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'uncut',
        loadChildren: () =>
            import('@app/uncut/uncut.module').then((m) => m.UncutModule),
    },
    {
        path: 'apple/callback',
        component: AppleCallbackComponent,
    },
    {
        path: 'test',
        loadChildren: () =>
            import('@app/test/test.module').then((m) => m.TestModule),
    },
    {
        path: 'authentic',
        loadChildren: () =>
            import('@app/authentic/authentic.module').then(
                (m) => m.AuthenticModule,
            ),
    },
    // ads route
    { path: 'ads', component: AdsComponent },
    // Otherwise redirect to home
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        }),
    ],
})
export class AppRoutingModule {}
