import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { JwtService } from '../shared/services/jwt.service';
import { AuthService } from '../shared/services/auth.service';
import { environment } from '@environments/environment';
import { RouterService } from '@app/core/services/router.service';
import { StoreService } from '@app/core/services/store.service';
import { UtilsService } from '@app/core/services';
import { FirebasePushNotificationService } from '../shared/services/firebase-push-notification.service';

declare const FB: any;
declare const AppleID: any;

@Component({
    selector: 'app-root-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnDestroy {
    passwordVisible = false;
    error?: string;
    submitting = false;

    loading: boolean = false;

    @Input() isDrawer: boolean = false;
    @Output() nzClosable = new EventEmitter<boolean>();
    isToggleSignup = false;
    // @Output() isDrawer: boolean = false;
    loginForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private jwtService: JwtService,
        private authService: AuthService,
        private routerService: RouterService,
        private router: Router,
        private store: StoreService,
        private utilsService: UtilsService,
        private firebasePushNotificationService: FirebasePushNotificationService,
    ) {
        this.utilsService.toggleLockScroll(true);

        this.loginForm = this.fb.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
            remember: [true],
        });
    }

    ngOnDestroy(): void {
        this.utilsService.toggleLockScroll(false);
    }

    handleSubmit() {
        this.submitting = true;
        if (this.loginForm.invalid) {
            Object.values(this.loginForm.controls).map((control) => {
                control.markAsDirty();
                control.updateValueAndValidity();
            });
            return;
        }
        const { email, password } = this.loginForm.value;
        this.authService.login(email, password).subscribe(async (result) => {
            if (result.data?.loginV2.__typename === 'AuthError') {
                this.loginForm.controls['email'].setErrors({ invalid: true });
                this.loginForm.controls['password'].setErrors({
                    invalid: true,
                });
            }
            if (result.data?.loginV2.__typename === 'AuthPayload') {
                localStorage.setItem(
                    environment.tokenKey,
                    result.data.loginV2.token,
                );
                this.authService.getMe().subscribe((authMeResult) => {
                    this.store.setUserProfile(authMeResult.data.me);
                });

                this.firebasePushNotificationService.saveRegistrationToken();

                if (this.isDrawer) {
                    this.router.navigate(['']);
                    this.nzClosable.emit();
                    return;
                }
                this.router.navigate(['']);
            }

            return result;
        });

        return;
    }

    public async facebookPromise(jwtService) {
        new Promise<void>((resolve) => {
            // wait for facebook sdk to initialize before starting the angular app
            (window as any)['fbAsyncInit'] = function () {
                if (FB != null && FB.XFBML != null) {
                    FB.XFBML.parse();
                }

                FB.init({
                    appId: environment.facebookAppId,
                    cookie: true,
                    xfbml: true,
                    version: 'v13.0',
                });

                // auto authenticate with the api if already logged in with facebook
                FB.getLoginStatus(({ authResponse }: any) => {
                    if (authResponse) {
                        jwtService.apiAuthenticate(authResponse.accessToken);
                    } else {
                        resolve();
                    }
                });
            };

            (function (d, s, id) {
                let js = null;
                const fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }).then(() => {
            this.jwtService.login();
            if (this.isDrawer) {
                this.nzClosable.emit();
                return;
            }
        });
    }

    public async facebook() {
        this.loading = true;
        return this.facebookPromise(this.jwtService)
            .then(() => {
                // this.jwtService.login(() => (this.loading = false));
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
    }

    public async apple() {
        try {
            AppleID.auth.init({
                clientId: 'com.review-ty.web-app',
                scopes: 'email name',
                redirectURI: 'https://review-ty.com/callback',
                state: 'init',
                nonce: 'test',
                usePopup: true,
            });
            const data = await AppleID.auth.signIn();

            this.authService.loginWithApple(data).subscribe((result) => {
                if (result.data?.loginByAppleV2.__typename === 'AuthError') {
                    //
                }

                if (result.data?.loginByAppleV2.__typename === 'AuthPayload') {
                    localStorage.setItem(
                        environment.tokenKey,
                        result.data.loginByAppleV2.token,
                    );

                    this.authService.getMe().subscribe((authMeResult) => {
                        // const currentDate = new Date();
                        // const tokenExpireDate = currentDate.setMonth(
                        // 	currentDate.getMonth() + 1,
                        // );
                        // this.cookieService.put(
                        // 	'me',
                        // 	JSON.stringify({
                        // 		id: authMeResult?.data?.me.id,
                        // 		avatar: authMeResult?.data?.me?.avatar?.url,
                        // 		account: authMeResult?.data?.me?.account,
                        // 		isShopUser: !!authMeResult?.data?.me?.shop,
                        // 	}),
                        // 	{
                        // 		expires: new Date(tokenExpireDate),
                        // 	},
                        // );

                        this.store.setUserProfile(authMeResult.data.me);
                    });
                    this.firebasePushNotificationService.saveRegistrationToken();
                    this.router.navigate(['']);
                }

                return result;
            });
        } catch (error) {
            console.error(error);
        }
    }

    toggleSignupAccount() {
        this.isToggleSignup = !this.isToggleSignup;
    }
}
