import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { RouterService } from '@app/core/services';
import { StoreService } from '@app/core/services/store.service';
import { ValidationService } from '@app/core/services/validation.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from '../shared/services/auth.service';
import { JwtService } from '../shared/services/jwt.service';
declare const FB: any;
declare const AppleID: any;
@Component({
    selector: 'app-root-signup',
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss',
})
export class SignupComponent {
    passwordVisible = false;
    error?: string;
    submitting = false;
    isShowVerifyEmail = false;

    @Output() toggleTab = new EventEmitter();
    @Input() isDrawer: boolean = false;
    @Output() nzClosable = new EventEmitter<boolean>();
    isToggleSignup = false;
    // @Output() isDrawer: boolean = false;
    signupForm: FormGroup;

    data = [
        {
            label: 'common.password',
            value: 'password',
            showPassword: false,
        },
        {
            label: 'profile.settings.change_password_text.confirm_password',
            value: 'confirmPassword',
            showPassword: false,
        },
    ];

    constructor(
        private fb: FormBuilder,
        private jwtService: JwtService,
        private authService: AuthService,
        private routerService: RouterService,
        private router: Router,
        private store: StoreService,
        private msgService: NzMessageService,
        private translateService: TranslateService,
        public validationService: ValidationService,
    ) {
        this.signupForm = this.fb.group(
            {
                email: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required, Validators.minLength(8)]],
                confirmPassword: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        this.matchPassword.bind(this),
                    ],
                ],
            },
            {
                validator: this.validationService.getErrorMessage,
            },
        );
    }

    // ngOnInit(): void {
    // 	setTimeout(() => {
    // 		this.routerService.triggerMobileTransparentLayoutChange(true);
    // 	}, 1);
    // }

    matchPassword(control: FormControl): { [s: string]: boolean } | null {
        if (
            this.signupForm &&
            control.value !== this.signupForm.controls['password'].value
        ) {
            return { passwordMismatch: true };
        }
        return null;
    }

    // ngOnDestroy(): void {
    // 	this.routerService.triggerMobileTransparentLayoutChange(false);
    // }

    handleSubmit() {
        this.submitting = true;
        if (this.signupForm.invalid) {
            Object.values(this.signupForm.controls).map((control) => {
                control.markAsDirty();
                control.updateValueAndValidity();
            });
            return;
        }

        const { email, password } = this.signupForm.value;
        this.authService
            .checkInformationByUser({
                where: {
                    email,
                },
            })
            .subscribe(({ data, ...results }) => {
                if (data?.user?.id) {
                    this.signupForm.controls['email'].setErrors({
                        emailAlready: true,
                    });
                    this.signupForm.controls['email'].markAllAsTouched();
                    this.msgService.error(
                        this.translateService.instant(
                            'Email đã có người sử dụng',
                        ),
                    );
                    return;
                } else {
                    this.store.setEmailPasswordSignup({ email, password });
                    this.authService
                        .generateOTP({
                            email,
                        })
                        .subscribe(({ data }) => {
                            if (data.generateOTP) {
                                this.isShowVerifyEmail = true;
                            }
                        });
                }
            });

        return;
    }

    public async facebook() {
        return new Promise<void>((resolve) => {
            // wait for facebook sdk to initialize before starting the angular app
            (window as any)['fbAsyncInit'] = function () {
                if (FB != null && FB.XFBML != null) {
                    FB.XFBML.parse();
                }

                FB.init({
                    appId: environment.facebookAppId,
                    cookie: true,
                    xfbml: true,
                    version: 'v13.0',
                });

                // auto authenticate with the api if already logged in with facebook
                FB.getLoginStatus(({ authResponse }: any) => {
                    if (authResponse) {
                        this.jwtService.apiAuthenticate(
                            authResponse.accessToken,
                        );
                    } else {
                        resolve();
                    }
                });
            };

            (function (d, s, id) {
                let js = null;
                const fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }).then(() => {
            this.jwtService.login();
        });
    }

    public async apple() {
        try {
            AppleID.auth.init({
                clientId: 'com.review-ty.shop-admin',
                scopes: 'email name',
                redirectURI: 'https://shop-admin.review-ty.com/apple/callback',
                state: 'init',
                nonce: 'test',
                usePopup: true,
            });
            const data = await AppleID.auth.signIn();

            this.authService.loginWithApple(data).subscribe((result) => {
                if (result.data?.loginByAppleV2.__typename === 'AuthError') {
                    //
                }

                if (result.data?.loginByAppleV2.__typename === 'AuthPayload') {
                    localStorage.setItem(
                        environment.tokenKey,
                        result.data.loginByAppleV2.token,
                    );

                    this.router.navigate(['']);
                }

                return result;
            });
        } catch (error) {
            console.error(error);
        }
    }

    toggleSignupAccount() {
        this.isToggleSignup = !this.isToggleSignup;
    }

    handleBack() {
        this.toggleTab.emit();
    }

    close() {
        this.isShowVerifyEmail = false;
    }
}
